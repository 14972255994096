import { EditOutlined } from "@ant-design/icons";
import { Card, Row, Col } from "antd";

function Contact() {
  return (
    <Row>
      <Col span={12} xs={24} sm={24} md={12} lg={12}>
        <Card
          actions={[
            <EditOutlined
              onClick={() => {
                alert("Yes");
              }}
            />,
          ]}
        >
          <Card.Meta title="Contact Us" description="" />
        </Card>
      </Col>
    </Row>
  );
}

export default Contact;
